<template>
  <div>
    <div>
      <div>
        <ValidationObserver ref="formCreateSeller">
          <form>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="type" :options="staff_type" nameSelector="name" optionSelector="id" field_name="Typ" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="name" type="text" field_name="Namn" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="email" type="text" field_name="Email" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="phone" type="text" field_name="Telefon" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <ValidationProvider name="Födelsedatum" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Födelsedatum<b class="text-red-500 ml-1">*</b></label>
                    <date-picker :lang="date_picker_lang" :key="date_picker_langKey" class="mt-2" v-model="birth_date" value-type="format" format="YYYY-MM-DD"></date-picker>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5" v-if="type != 'tech'"></div>
            <div class="flex flex-wrap" v-if="type != 'tech'">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="bank_name" type="text" field_name="Bank" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="bank_identifier" type="text" field_name="ICE" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="bank_account_number" type="text" field_name="Kontonummer" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5" v-if="type == 'seller'"></div>
            <div class="flex flex-wrap" v-if="type == 'seller'">
              <div class="w-full md:w-1/4 px-2 py-2">
                <BaseInput v-model="yearly_budget_alarm" type="number" field_name="Årligt budgetlarm" rules="required" @change="divideMonthlyAlarms()" />
              </div>
              <div class="w-full md:w-1/4 px-2 py-2">
                <BaseInput v-model="yearly_budget_revenue" type="number" field_name="Årliga budgetintäkter (SEK)" rules="required" @change="divideMonthlyRevenue()" />
              </div>
              <div class="w-full md:w-2/4 px-2 py-2 bg-primary-50">
                <div class="w-full text-sm font-bold mb-4">Månatlig<b class="text-red-500 ml-1">*</b></div>
                <div class="w-full flex flex-wrap mb-4">
                  <div class="w-1/3 my-auto text-sm font-bold">Månad</div>
                  <div class="w-1/3 my-auto px-2 text-sm font-bold">Budgetlarm</div>
                  <div class="w-1/3 my-auto px-2 text-sm font-bold">Budgetintäkter (SEK)</div>
                </div>
                <div class="w-full flex flex-wrap my-1 border-b border-gray-300" v-for="m in monthly_budget" :key="m.id">
                  <div class="w-1/3 text-sm my-auto">
                    {{ m.name }}
                  </div>
                  <div class="w-1/3 my-auto px-2">
                    <ValidationProvider :name="m.name" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="number" v-model="m.alarms" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" @change="calculateYearlyAlarms()" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="w-1/3 my-auto px-2">
                    <ValidationProvider :name="m.name" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input type="number" min="0" v-model="m.revenue" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" @change="calculateYearlyRevenue()" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full px-2">
                <div class="clearfix">
                  <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createStaff()">
                    Spara
                    <BaseIcon icon="arrow-right" class="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  title() {
    return `Admin - Skapa ny personal`;
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      staff_type: [
        { id: "seller", name: "Säljare" },
        { id: "support", name: "Support" },
        { id: "admin", name: "Admin" },
        { id: "tech", name: "Tekniker" },
      ],
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
      type: "",
      name: "",
      phone: "",
      email: "",
      birth_date: "",
      bank_name: "",
      bank_identifier: "",
      bank_account_number: "",
      yearly_budget_alarm: 0,
      yearly_budget_revenue: 0,
      monthly_budget: [
        { id: 1, name: "Januari", alarms: 0, revenue: 0 },
        { id: 2, name: "Februari", alarms: 0, revenue: 0 },
        { id: 3, name: "Mars", alarms: 0, revenue: 0 },
        { id: 4, name: "April", alarms: 0, revenue: 0 },
        { id: 5, name: "Maj", alarms: 0, revenue: 0 },
        { id: 6, name: "Juni", alarms: 0, revenue: 0 },
        { id: 7, name: "Juli", alarms: 0, revenue: 0 },
        { id: 8, name: "Augusti", alarms: 0, revenue: 0 },
        { id: 9, name: "September", alarms: 0, revenue: 0 },
        { id: 10, name: "Oktober", alarms: 0, revenue: 0 },
        { id: 11, name: "November", alarms: 0, revenue: 0 },
        { id: 12, name: "December", alarms: 0, revenue: 0 },
      ],
      seller_number: 0,
    };
  },

  methods: {
    createStaff() {
      this.$refs.formCreateSeller.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          type: this.type,
          name: this.name,
          phone: this.phone,
          email: this.email,
          birth_date: this.birth_date,
          yearly_budget_alarm: this.yearly_budget_alarm,
          yearly_budget_revenue: this.yearly_budget_revenue,
          monthly_budget: this.monthly_budget,
          seller_number: this.seller_number,
          bank_name: this.bank_name,
          bank_identifier: this.bank_identifier,
          bank_account_number: this.bank_account_number,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/staff`, data)
          .then(() => {
            // eslint-disable-line
            this.$router.push("/admin/staff");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    divideMonthlyAlarms() {
      let d = this.distributeMonthly(this.yearly_budget_alarm);

      for (let i = 0; i < d.length; i++) {
        let monthlyAlarms = d[i];
        this.monthly_budget[i].alarms = monthlyAlarms;
      }
    },

    divideMonthlyRevenue() {
      let d = this.distributeMonthly(this.yearly_budget_revenue);

      for (let i = 0; i < d.length; i++) {
        let monthlyRevenue = d[i];
        this.monthly_budget[i].revenue = monthlyRevenue;
      }
    },

    distributeMonthly(val) {
      let distributeInteger = function*(total, divider) {
        if (divider === 0) {
          yield 0;
        } else {
          let rest = total % divider;
          let result = total / divider;

          for (let i = 0; i < divider; i++) {
            if (rest-- > 0) {
              yield Math.ceil(result);
            } else {
              yield Math.floor(result);
            }
          }
        }
      };

      let d = [];
      for (let m of distributeInteger(val, 12)) {
        d.push(m);
      }

      return d;
    },

    calculateYearlyAlarms() {
      let monthlyAlarms = _.sumBy(this.monthly_budget, function(o) {
        return parseInt(o.alarms);
      });
      this.yearly_budget_alarm = monthlyAlarms;
    },

    calculateYearlyRevenue() {
      let monthlyRevenue = _.sumBy(this.monthly_budget, function(o) {
        return parseInt(o.revenue);
      });
      this.yearly_budget_revenue = monthlyRevenue;
    },
  },

  watch: {
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
  created() {
    this.setPageTitle(`Admin - Skapa ny personal `, "create_staff");
  },
};
</script>
